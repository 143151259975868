const wakeLockSupported = 'wakeLock' in navigator;

let wakeLock: WakeLockSentinel | null = null;
let interactionCount = 0;

const acquireWakelock = async () => {
  try {
    wakeLock = await navigator.wakeLock.request('screen');
    wakeLock.addEventListener('release', () => {
      interactionCount = 0;
      wakeLock = null;
    });
  } catch (err) {
    console.error('Failed to acquire wakelock', err);
  }
};

const touché = () => {
  interactionCount = interactionCount += 1;
  if (interactionCount >= 2) {
    acquireWakelock();
    document.removeEventListener('touchend', touché);
    document.removeEventListener('click', touché);
  }
};
if (wakeLockSupported) {
  document.addEventListener('touchend', touché);
  document.addEventListener('click', touché);

  document.addEventListener('visibilitychange', async () => {
    if (wakeLock == null && document.visibilityState === 'visible') {
      document.addEventListener('touchend', touché);
      document.addEventListener('click', touché);
    }
  });
}
