const storage: {
  session: Storage | ShortlivedStorage;
  local: Storage | ShortlivedStorage;
} = {
  session: window.sessionStorage,
  local: window.localStorage,
};

const prefix = () => `pin-tipp`;

export function init() {
  if (!isLocalStorageSupported()) {
    storage.local = createStorageSimulator();
  }
  if (!isSessionStorageSupported()) {
    storage.session = createStorageSimulator();
  }
}

export function setItem<Item>(
  storageType: 'local' | 'session',
  collectionId: string,
  items: Item
) {
  try {
    storage[storageType].setItem(
      String(prefix() + '-' + collectionId),
      JSON.stringify(items)
    );
  } catch (error) {
    console.error(error);
  }
}

export function removeItem(
  storageType: 'local' | 'session',
  collectionId: string
) {
  storage[storageType].removeItem(String(prefix() + '-' + collectionId));
}

export function getItem<Item>(
  storageType: 'local' | 'session',
  collectionId: string,
  initialValue: Item
): Item;
export function getItem<Item>(
  storageType: 'local' | 'session',
  collectionId: string
): Item | undefined;
export function getItem<Item>(
  storageType: 'local' | 'session',
  collectionId: string,
  initialValue?: Item
) {
  const value = JSON.parse(
    storage[storageType].getItem(prefix() + '-' + collectionId)
  );
  if (value == null) {
    if (initialValue !== undefined) {
      setItem(storageType, collectionId, initialValue);
      return initialValue;
    }
    return undefined;
  }
  return value;
}

export const clearStorage = (storageType: 'local' | 'session') => {
  for (let i = 0; i < storage[storageType].length; i++) {
    const key = storage[storageType].key(i);
    if (key?.includes(prefix())) {
      storage[storageType].removeItem(key);
    }
  }
};

let localStorageSupported: boolean | null = null;
// When testing if storage is supported we can't just check if `getItem()` works,
// we need to actually attempt to write a value.
// See https://gist.github.com/paulirish/5558557
export function isLocalStorageSupported() {
  if (localStorageSupported == null) {
    try {
      window.localStorage.setItem('isLocalStorageSupported', 'test');
      window.localStorage.removeItem('isLocalStorageSupported');
      localStorageSupported = true;
    } catch (error) {
      localStorageSupported = false;
    }
  }

  return localStorageSupported;
}

let sessionStorageSupported: boolean | null = null;
// When testing if storage is supported we can't just check if `getItem()` works,
// we need to actually attempt to write a value.
// See https://gist.github.com/paulirish/5558557
export function isSessionStorageSupported() {
  if (sessionStorageSupported == null) {
    try {
      window.sessionStorage.setItem('isSessionStorageSupported', 'test');
      window.sessionStorage.removeItem('isSessionStorageSupported');
      sessionStorageSupported = true;
    } catch (error) {
      sessionStorageSupported = false;
    }
  }

  return sessionStorageSupported;
}

interface ShortlivedStorage {
  storage: Record<string, any>;
  getItem: (collectionId: any) => any;
  setItem: (collectionId: any, items: any) => void;
  removeItem: (collectionId: any) => void;
  key: (index: number) => string;
  length: 0;
}

const createStorageSimulator = (): ShortlivedStorage => {
  const storage: Record<string, any> = {};
  const length = 0;
  const getItem = (collectionId: string) => {
    if (storage[collectionId] == null) {
      return null;
    }
    return storage[collectionId];
  };
  const setItem = (collectionId: string, items: any) => {
    storage[collectionId] = items;
  };
  const removeItem = (collectionId: string) => {
    delete storage[collectionId];
  };

  const key = (_index: number) => '';

  return { storage, getItem, setItem, removeItem, length, key };
};

init();

export const openedQuestionKey = 'open-question-id-before-login-v2';
