import * as Sentry from '@sentry/svelte';
import { v4 as uuidv4 } from 'uuid';
import { isFlagSetInLocalStorage } from './debuflags.js';
import { getItem, setItem } from './storage';

export const initSentry = () => {
  const configuration = {
    SENTRY_DSN:
      'https://ef0a76a390012886a480c557d8ba66dc@o124059.ingest.us.sentry.io/4507418041516032',
    DEBUG_SENTRY: isFlagSetInLocalStorage('pin-debug-sentry'),
    FULL_VERSION: import.meta.env.VITE_FULL_VERSION,
  };

  if (import.meta.env.PROD) {
    Sentry.init({
      debug: configuration.DEBUG_SENTRY,
      dsn: configuration.SENTRY_DSN,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.captureConsoleIntegration({ levels: ['error'] }),
      ],
      autoSessionTracking: true,
      normalizeMaxBreadth: 10,
      normalizeDepth: 3,
      sampleRate: 0.2,
      tracesSampleRate: 0.05,
      release: configuration.FULL_VERSION,
      environment: import.meta.env.VITE_APP_ENV,
    });

    Sentry.setUser({
      id: getSessionUUID(),
    });
  } else {
    console.log('Not sending to Sentry - SENTRY_DSN is not set');
  }
};

export const getSessionUUID = function () {
  const uuid = getItem<string>('session', 'userid');
  if (uuid) {
    return uuid;
  }
  const newUUID = uuidv4();
  setItem('session', 'userid', newUUID);
  return newUUID;
};
