import './styles/global.css';
import { initializeVersionCheck } from './util/versionCheck.js';
import { initializeSnowPlowTracker } from './util/snowplow.js';
import '@u-elements/u-tabs';
import './util/screenWake.js';
import './util/polyfill.js';
import { initSentry } from './util/sentry.js';

const initializeApp = async () => {
  initializeVersionCheck();
  initializeSnowPlowTracker();
  initSentry();

  const containerRef = document.getElementById('appentry')!;
  const App = (await import('./App.svelte')).default;
  return new App({
    target: containerRef,
  });
};

const app = initializeApp();
export default app;
